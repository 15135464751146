import React from "react";
import './styles.css';
import FastStartCard from "../../components/FastStartCard";
import { MdLocalActivity, MdStackedBarChart, MdAttachMoney, MdInfo, MdPerson, MdSettings } from "react-icons/md";
let admUser = sessionStorage.getItem('adm');
const FastStart = () => {
    return (
        <div>
            {admUser === true ?
                <div className="bg-abstract bg-cover bg-center h-[100%]  flex flex-col justify-center items-center sm:h-screen">
                    <div className="p-[5%]">
                        <h1 className="p-8 text-4xl"> Olá, <span className="font-bold">Enzo</span>!</h1>
                        <div className="grid sm:grid-cols-3 gap-4 grid-cols-1">
                            <FastStartCard route={'/admin/campanhas'} icon={<MdLocalActivity />} title={'Campanhas'} />
                            <FastStartCard icon={<MdAttachMoney />} title={'Pagamentos'} />
                            <FastStartCard icon={<MdInfo />} title={'Informações'} />
                            <FastStartCard icon={<MdPerson />} title={'Perfil'} />
                            <FastStartCard icon={<MdSettings />} title={'Configurações'} />
                        </div>
                    </div>

                </div>
                :
                <div className="bg-abstract bg-cover bg-center flex flex-col justify-center items-center h-screen">
                    <h1 className="p-8 text-4xl"> Seja <span className="font-bold">Bem vindo</span>!</h1>
                    <div className="flex flex-col sm:flex-row justify-around items-center w-[40%]">
                        <FastStartCard route={'/user/campanhas'} icon={<MdLocalActivity />} title={'Campanhas'} />
                        <FastStartCard route={'/user/minhas-campanhas'} icon={<MdStackedBarChart />} title={'Meus bilhetes'} />
                        {/* <FastStartCard className="sm:pt-0 pt-3" route={'/login'} icon={<MdPerson />} title={'login'} /> */}
                    </div>
                </div>}
        </div>
    )
}
export default FastStart;

