import React, { useState } from 'react';
import { initMercadoPago, Payment } from '@mercadopago/sdk-react'
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png'
import QRCode from "qrcode.react";
import { ToastContainer, } from 'react-toastify';
import { toastError, toastSuccess } from '../../utils';
import { validatePayment } from '../../Requests';
import { MdCancel, MdNewReleases } from 'react-icons/md';
import CountDown from '../../components/CoundDown';
import Modal from '../../components/Modal';

initMercadoPago('APP_USR-becad040-edef-4925-bed9-48ee05ee5cb2');


const CheckOut = () => {
    const dataToPayment = JSON.parse(sessionStorage.getItem('paymentInfos'));
    const navigator = useNavigate()
    const TOKEN = sessionStorage.getItem('token')
    const [qrCode, setQrCode] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [validPayment, setValidPayment] = useState(false);
    const [loader, setLoader] = useState(true);

    const checkValidation = async (id) => {
        try {
            let res = await validatePayment(id);
            setTimeout(() => setValidPayment(true), 60 * 1000); // call again after 30 seconds
            ;

        } catch (error) {
            console.log(error);
        }
    };

    const handleCopy = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');

            toastSuccess("Código copiado!")
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
            toastError("Falha ao copiar o código!")
        }
        document.body.removeChild(textArea);
    };

    const initialization = {
        amount: dataToPayment.totalPrice,
    };
    const customization = {
        paymentMethods: {
            bankTransfer: "all",
        },
    };
    const onSubmit = async ({ selectedPaymentMethod, formData }) => {
        let apiUrl = `https://api.premierbrasilumbix.com.br/payments/campaigns/${dataToPayment.id}`;
        const token = `Bearer ${TOKEN}`;
        return new Promise((resolve, reject) => {
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token
                },
                body: JSON.stringify({
                    "email": formData.payer.email,
                    "ticketQuantity": dataToPayment.qtdTickets,
                    "paymentMethod": formData.payment_method_id,
                    "token": formData.token != null ? formData.token : '',
                    "issuerId": formData.issuer_id != null ? formData.issuer_id : ''
                }),
            })
                .then((response) => response.status === 201 ? response.json() : null)
                .then((response) => {
                    if (formData.payment_method_id !== 'pix') {
                        window.URL('/user/campanhas')
                    }
                    else {
                        setQrCode(response.qr_code)
                        checkValidation(dataToPayment.id)

                    }
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        });
    };
    const onError = async (error) => {
        setShowModal(true)
        console.log(error);
    };
    const onReady = async () => {
        setShowModal(true)
        setLoader(false)
    };
    return (
        <div className='bg-abstract bg-cover h-[100vh] w-[100vw] flex m-auto sm:flex-row flex-col-reverse justify-around items-center'>
            <div className='flex flex-col items-center sm:items-start'>
                {qrCode == null ?
                    <button onClick={() => {
                        window.history.back();
                    }} className={loader ? 'hidden' : 'inner-block font-bold text-sm bg-[#005B80]   text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline'}>
                        Voltar
                    </button> : <></>
                }
                <div className='max-h-[70vh] p-4'>
                    {!qrCode && <Payment
                        initialization={initialization}
                        customization={customization}
                        onSubmit={onSubmit}
                        onReady={onReady}
                        onError={onError}
                    />}
                    {qrCode != null ? <div className='flex flex-col items-center justify-around  border-slate-300 rounded-lg shadow-lg shadow-gray-500'>
                        {
                            validPayment === true ? <div className='flex flex-col items-center justify-center w-[100%] p-[5%] bg-green-400'>
                                <p className='text-lg text-white font-bold'>Pagamento aprovado!</p>
                                <MdNewReleases color='white' size={30} />
                                <p className='text-sm text-white font-bold'>Verifique "Meus bilhetes" ou confira seu email (Caixa de entrada ou Spam)</p>
                            </div> : <CountDown></CountDown>
                        }
                        <QRCode
                            id={qrCode}
                            value={qrCode}
                            size={290}
                            level={"H"}
                            includeMargin={true}
                        />
                        <div className='flex flex-col h-[20vh] p-[5%] justify-around items-end'>
                            <div className='flex flex-row justify-center w-[100%]'>
                                <p className=' bg-white max-w-[150px]  overflow-x-scroll text-nowrap rounded-s-md'>{qrCode}</p>
                                <button onClick={() => handleCopy(qrCode)} className='ml-3 font-bold text-sm bg-[#005B80] text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                                    Copiar
                                </button>
                            </div>
                            <button onClick={() => { navigator('/user/minhas-campanhas') }} className='font-bold w-[100%] text-sm bg-[#005B80] text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                                Meus bilhetes
                            </button>
                        </div>
                    </div> : <></>}
                </div>

                {
                    loader && (
                        <span class="relative flex h-9 w-9">
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-9 w-9 bg-sky-500"></span>
                        </span>
                    )
                }
            </div>
            <ToastContainer />
            <Modal
                openModal={showModal}
                visibleContent={<MdNewReleases color='' size={1} />
                }
                child={
                    <div className='flex flex-col items-center justify-around sm:w-[50%] w-[80%] h-[40%] p-[5%] rounded-lg bg-slate-200'>
                        <div className='flex flex-col items-center justify-around'>
                            <MdCancel color='red' size={50} />
                            <p className='text-lg text-red-600 font-bold'>Erro ao processar pagamento</p>
                        </div>
                        <p className='text-sm text-black '>Tente novamente ou entre em contato conosco</p>
                        <button className='bg-green-400 text-white font-bold rounded-lg p-[6%]' onClick={() => { window.location.href = "https://wa.me/5588998055660" }}>Whatsapp</button>
                    </div>
                }
            />
            <img className='sm:w-[30%] w-[70%] py-2' alt="logo" src={Logo} />
        </div>
    )
}

export default CheckOut;


//5031433215406351