import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCumb from "../../../components/BreadCumb";
import { getMyTickets } from "../../../Requests";
import { ToastContainer, toast } from "react-toastify";
import { MdSearch } from "react-icons/md";
import BilheteCard from "../components/BilhetesCard";
import { toastInfo } from "../../../utils";

const MeusBilhetes = () => {

    const [datas, setDatas] = useState(null);
    const [searchParam, setSearchParam] = useState(null);
    useEffect(() => {
        toastInfo("Pesquise por seus bilhetes usando Telefone ou email")

    }, []);

    const loadData = async () => {
        try {
            var response = await getMyTickets(searchParam.trim())
            setDatas(response)

        } catch (error) {
            setDatas([])
        }
    }

    return (
        <div className="flex flex-col justify-between items-center w-[100vw] sm:w-[80vw]">
            <BreadCumb item='Bilhetes' subItens="" />
            <div className="w-[90%] sm:h-[70%] h-[60%] m-auto" >
                <div className="relative flex flex-row justify-around my-[10%]">
                    <input onChange={(e) => { setSearchParam(e.target.value) }}
                        className="shadow  appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="username"
                        type="text"
                        placeholder="Email ou telefone" />
                    <button onClick={loadData} className=" p-[3%] rounded-lg bg-[#005B80]">
                        <MdSearch />
                    </button>
                </div>

                {datas != null &&
                    (<div className="flex flex-col m-auto w-[100%] ">
                        {datas.length == 0 ?
                            <div className="h-[10%] w-[80%] p-[5%] flex justify-center items-center bg-[#FFB901] text-white m-auto rounded-lg border-slate-300 shadow-lg shadow-gray-500">
                                Localize seus tickes com email cadastrado no pagamento!
                            </div> : <>
                                {datas.map((data, index) => (
                                    <div className="border-slate-300 sm:w-[90%] w-[90vw] bg-white rounded-lg shadow-lg shadow-gray-500 p-[4%] grid sm:grid-cols-2 gap-12 justify-between items-center grid-cols-1">
                                        <BilheteCard name={data.campaign.name} numbers={data.numbers} entred={true} />
                                    </div>
                                ))}
                            </>
                        }

                    </div>)
                }
                {datas == null &&
                    <div className="h-[10%] w-[80%] flex justify-center items-center bg-[#FFB901] text-white m-auto rounded-lg border-slate-300 shadow-lg shadow-gray-500">
                        Localize seus bilhetes com número ou email cadastrado!
                    </div>
                }
            </div>

            <ToastContainer />
        </div>
    )
}
export default MeusBilhetes;