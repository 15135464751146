import React, { useState } from "react";
import { atualizarCampanha } from "../../../Requests";
import { sucessAlert, toastError } from "../../../utils";
import Modal from "../../../components/Modal";
import { MdEdit } from "react-icons/md";

const EditInsight = ({id, campaignName, campaignPrice}) => {
    const [name, setName] = useState    (null);
    const [price, setPrice] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [realizedAt, setRealizedAt] = useState(null);
    const [campaignStatus, setCampaignStatus] = useState(true); // Add a state for campaign status

    const handleSubmit = async () => {
        try {
             await atualizarCampanha(id,
                name,
                price,
                // realizedBy,
                realizedAt,
                campaignStatus,
            )
            setShowModal(false)
            sucessAlert('Campanha alterada com sucesso!');
            window.location.reload();


        } catch (error) {
            toastError('Algo deu errado');
        }
    };
    return (
        <Modal
            openModal={showModal}
            visibleContent={<MdEdit />}
            child={
                <div
                    className="bg-white rounded shadow-md p-4 max-w-md mx-auto"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div>
                        <form>
                            <div className="mb-4 w-[100%]">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                    Nome da Campanha
                                </label>
                                <input value={campaignName} onChange={(e) => setName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="name" type="text" placeholder="Celta 2010" />
                            </div>
                            <div className="mb-4 w-[100%]">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
                                    Preço do ticket
                                </label>
                                <input value={campaignPrice} onChange={(e) => setPrice(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="name" type="text" placeholder="0.4" />
                            </div>
                            <div className="mb-4 w-[100%]">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="realizedAt">
                                    Data do sorteio
                                </label>
                                <input onChange={(e) => setRealizedAt(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="name" type="date" placeholder="" />
                            </div>
                            <div className="mb-4 w-[100%]">
                                <input
                                    type="checkbox"
                                    id="slider"
                                    class="hidden peer"
                                    onChange={(e) => setCampaignStatus(e.target.checked)}
                                />
                                <label for="slider" class="bg-gray-400 h-6 w-12 rounded-lg px-5 peer-checked:bg-blue-500 peer-checked:after:translate-x-full after:absolute after:top-0.5 after:left-0.5 after:h-5 after:w-5 after:bg-white after:rounded-lg after:transition after:duration-300">Campanha {campaignStatus === true ? 'Aberta' : 'Fechada'}</label>

                            </div>
                        </form>
                    </div>
                    <button className="bg-[#005B80] text-white font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        onClick={() => handleSubmit()}>
                        Editar
                    </button>
                </div>
            }
        />
    )
}

export default EditInsight;