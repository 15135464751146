import React from "react"
import CadastroForm from "../components/CadastroForm";

const Cadastro = () => {

    return (
        <div className="bg-abstract bg-cover flex sm:flex-row flex-col bg-center 
    h-screen justify-around items-center w-[100%]">
            <div className="sm:h-[80%] h-[75%] bg-white shadow-md sm:w-[40vw] w-[90%]
         border-gray-400 border rounded-md p-[3%] ">
                <CadastroForm />
            </div>

        </div>)
}
export default Cadastro