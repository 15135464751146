import React from "react";
import { Link } from "react-router-dom";

const CampanhaCard = ({ name, img }) => {
    return (
        <div class="flex md:w-[20vw] w-[100%] h-[30vh] sm:m-[20px] rounded-xl border-2 border-gray-300/75 shadow-lg shadow-gray-800"
            style={{
                backgroundImage: `url(${img})`, backgroundSize: '100% 100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                 }}>
            <h1 class="p-[5%] text-white">{name}</h1>
        </div>
    )
}

export default CampanhaCard