import React, { useState, useEffect } from 'react';
import {  useLocation } from 'react-router-dom';
import { getCampaignsDetails, getUsers } from '../../Requests';
import { MdSearch, MdVisibility } from 'react-icons/md'
;
import Loader from '../../components/Loader';
import InfoCard from './components/InfoCard';
import EditInsight from './components/EditInsight';
import ProgressBar from '../../components/ProgressBar';
import Modal from '../../components/Modal';

const Insights = () => {
    const location = useLocation();
    const { id } = location.state;

    const [data, setData] = useState(null);
    const [participants, setParticipants] = useState(null);
    const [searchParam, setSearchParam] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [seeTickets, setSeeTickets] = useState(false);

    const editCampaign = () => {
        setIsOpen(true);
    };

    const openTickets = () => {
        setSeeTickets(true);
    };

    useEffect(() => {
        loadData();
        listUsers();
    }, []);

    const loadData = async () => {
        try {
            const response = await getCampaignsDetails(id);
            setData(response);
        } catch (error) {
            console.error(error);
        }
    };

    const listUsers = async () => {
        try {
            const response = await getUsers(id);
            setParticipants(response);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="w-[80vw] h-[90%] flex flex-col">
            {data ? (
                <div>
                    <div className="w-[90%] flex flex-row justify-between items-center">
                        <h1 className="p-[5%] text-[#005B80] text-[2rem] font-bold">{data.name}</h1>
                        <div className="flex flex-row">
                            <button onClick={editCampaign} className="bg-[#005B80] text-white font-bold p-4 rounded focus:outline-none focus:shadow-outline">
                                <EditInsight id={id} campaignName={data.name}
                                    campaignPrice={data.entryPrice} />
                            </button>
                        </div>
                    </div>

                    {/* campaign stats */}
                    <div className="flex sm:flex-row flex-col w-[100%] text-center justify-around items-center p-[5%]">
                        <InfoCard color={'text-[#FA7100]'} title={'Vendidos Hoje'} content={''} />
                        <InfoCard color={'text-[#005B80]'} title={'Data de início'} content={data.realizedAt == null ? '__ / __ / ____' : new Intl.DateTimeFormat('pt-BR').format(new Date(data.realizedAt))} />
                        <InfoCard color={'text-[#FA7100]'} title={'Saldo Atual'} content={`R$: ${data.metadata.balance}`} />
                        <InfoCard color={'text-[#005B80]'} title={' Status da Campanha'} content={data.status == 'open' ? 'Aberta' : 'Fechada'} />
                        {/* ... */}
                    </div>
                    <div className='flex items-center justify-center w-[100%] m-auto mb-[5%]'>
                        <ProgressBar color='#005B80' progress={data.metadata.ticketsRemaining / data.metadata.ticketsAmount} />
                    </div>
                    {/* participant table */}
                    <div className="bg-white rounded-lg w-[90%] p-[5%] mx-auto justify-around flex">
                        <div className="overflow-y-auto">
                            <div className="relative my-[4%]">
                                <input
                                    onChange={(e) => setSearchParam(e.target.value)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="username"
                                    type="text"
                                    placeholder="Pesquisar usuário"
                                />
                                <span onClick={loadData} className="absolute right-3 top-3">
                                    <MdSearch />
                                </span>
                            </div>
                            {participants ? (
                                <>
                                <table className="table-auto w-[100%] text-center">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2">Nome</th>
                                            <th className="px-4 py-2">Telefone</th>
                                            <th className="px-4 py-2">email</th>
                                            <th className="px-4 py-2">Bilhetes</th>
                                            <th className="px-4 py-2">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody className="border-t border-b h-[100px] overflow-y-auto overflow-x-auto">
                                        {participants.map((participant, idx) => (
                                            <tr key={idx} className="w-[100%]">

                                                <td className="px-4 py-2">{participant.user.name != null ? participant.user.name : '...'}</td>
                                                <td className="px-4 py-2">{participant.user.phone != null ? participant.user.phone : '...'}</td>
                                                <td className="px-4 py-2">{participant.user.email != null ? participant.user.email : '...'}</td>

                                                <td className="px-4 py-2 max-w-[300px] overflow-auto">
                                                    <button onClick={openTickets} className="font-bold p-4 rounded focus:outline-none focus:shadow-outline">
                                                        <Modal
                                                            openModal={seeTickets}
                                                            visibleContent={<MdVisibility color='#005B80' />}
                                                            child={
                                                                <div className="bg-white rounded shadow-md p-4 max-w-md mx-auto"
                                                                >
                                                                    <h1>Bilhetes</h1>
                                                                    <div className="w-[80%] h-[1px] bg-gray-300"></div>
                                                                    <p className='text-black'>
                                                                        {participant.tickets.join(', ')}
                                                                    </p>
                                                                </div>
                                                            }
                                                        />
                                                    </button>
                                                </td>
                                                <td className="px-4 py-2"></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                                                </>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            ) : <Loader />}
        </div>
    );
};

export default Insights;