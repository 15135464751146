import { useState, useEffect } from 'react';

const CountDown = () => {
    const [countdown, setCountdown] = useState({ mins: 10, secs: 0 });

    useEffect(() => {
        let intervalId;
            intervalId = setInterval(() => {
                if (countdown.secs === 0) {
                    if (countdown.mins === 0) {
                        clearInterval(intervalId);
                    } else {
                        setCountdown({ mins: countdown.mins - 1, secs: 59 });
                    }
                } else {
                    setCountdown({ mins: countdown.mins, secs: countdown.secs - 1 });
                }
            }, 1000);
        
        return () => clearInterval(intervalId);
    }, [countdown]);

    return (
        <div className='flex flex-col items-center justify-around p-[5%] border-slate-300 rounded-lg shadow-lg shadow-gray-500'>
            <p className='text-lg font-bold'>Tempo restante: {countdown.mins} minutos e {countdown.secs} segundos</p>
        </div>
    );
};

export default CountDown;