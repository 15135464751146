import React, { useEffect } from "react";

import { Outlet } from "react-router-dom";
import NavBar from "../../navbar/NavBar";
import { getValidarToken } from "../../Requests";

const Home = () => {
    const token = sessionStorage.getItem('token')
    useEffect(() => {
        validarToken()
    }, [])

    const validarToken = async (event) => {
        try {
            if (token != null) {
                const response = await getValidarToken()
                if (response === 200) {
                    return
                } else {
                    sessionStorage.clear()
                }

            }
        } catch (error) {
            console.log('erro na função de validar token')

        }
    }
    return (
        <div className="flex flex-row">
            <NavBar />
            <div className="bg-slate-300">
                <Outlet />
            </div>
        </div>
    )
}
export default Home;