import React, { useState } from 'react';

const Modal = ({ visibleContent, child, openModal }) => {
    const [showModal, setShowModal] = useState(openModal);
    
    return (
        <>
            <button
                type="button"
                onClick={() => setShowModal(true)}>
                {visibleContent}
            </button>
            {showModal ? (
                <div
                    className="fixed z-50 top-0 left-0 w-full h-full flex justify-center items-center bg-gray-700 bg-opacity-50"
                    onClick={() => setShowModal(false)}
                >
                    {child}
                </div>
            ) : null}
        </>
    );
};

export default Modal;