import React from "react";

const BreadCumb = ({ item, subItens }) => {
    return (
        <div className="flex items-center border-3 border-slate-300 shadow-lg shadow-gray-300 sm:pl-[10%] bg-[#FFFFFF] h-[20%] md:w-[100%] w-[100%]">
            <h1 className="text-[#FFB901] w-[50%] text-[2rem] font-bold " >
                {item}
                <br></br>
                <p className="text-[#FFB901] sm:w-[100%] w-[90vw]  ml-[20%] text-[1.3rem] font-medium ">
                    {subItens}
                </p>
            </h1>

        </div>
    )
}

export default BreadCumb;