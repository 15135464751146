
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { criarNovaCampanha } from '../../../Requests';
import { sucessAlert, toastError, toastSuccess } from '../../../utils';
import { ToastContainer } from 'react-toastify';


const CampanhaForm = () => {
    const [loading, setloading] = useState(false);
    const [name, setName] = useState(null);
    const [entryPrice, setEntryPrice] = useState(null);
    const [ticketAmount, setTicketAmount] = useState(null);
    const [goldenTicketsQuantity, setGoldenTicketsQuantity] = useState(null);
    const [realizedBy, setRealizedBy] = useState(null);
    const [realizedAt, setRealizedAt] = useState(null);
    const [prizeName, setPrizeName] = useState(null);
    const [prizeDescription, setPrizeDescription] = useState(null);
    const [prizePrice, setPrizePrice] = useState(null);
    const [prizeImageUrl, setPrizeImageUrl] = useState(null);

    const navigator = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setloading(true);

        let response = await criarNovaCampanha(
            name,
            entryPrice,
            ticketAmount,
            goldenTicketsQuantity,
            realizedBy,
            realizedAt,
            prizeName,
            prizeDescription,
            prizePrice,
            prizeImageUrl,
        )
        if (response !== 200 && response !== 201) {
            toastError('Algo deu errado');
        } else {
            sucessAlert('Campanha criada com sucesso!');
            navigator('/user/campanhas');
        }
    };
    useEffect(() => {

    }, []);

    // const openModal = () => {
    //     setShowModal(!showModal);
    // };

    return (
        <>
            <div className="w-[79vw] h-[90%] flex flex-col">
                <div className='w-[90%] flex flex-row justify-between items-center'>
                    <h1 className=' p-[5%] text-[#005B80] text-[2rem] font-bold'>Criação de novas campanhas  </h1>
                </div>
                <div className="modal-content">

                    <form onSubmit={handleSubmit} className=" flex flex-col justify-around items-center px-4 pt-6 pb-8 mb-4">
                        <div className="w-[90%] p-[3%] mb-[5%] flex flex-col justify-center bg-white  border-slate-300 rounded-lg shadow-lg shadow-gray-30">
                            <div className=" flex flex-row justify-center items-center w-[100%] mx-auto">
                                <div className="mb-4 w-[100%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                        Nome da Campanha
                                    </label>
                                    <input onChange={(e) => setName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="name" type="text" placeholder="Celta 2010" />
                                </div>

                            </div>
                            <div className='w-[100%] flex flex-row justify-between items-center m-auto'>
                                <div className="mb-4 w-[40%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="entryPrice">
                                        Valor do ticket
                                    </label>
                                    <input onChange={(e) => setEntryPrice(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="entryPrice" type="text" placeholder="0.1" />
                                </div>
                                <div className="mb-4 w-[40%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ticketAmount">
                                        Quantidade de tickets
                                    </label>
                                    <input onChange={(e) => setTicketAmount(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="ticketAmount" type="number" placeholder="1000" />
                                </div>



                            </div>
                            <div className=" flex flex-row justify-between items-center w-[100%] mx-auto">
                                <div className="mb-4 w-[30%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                        Realizadora do sorteio
                                    </label>
                                    <select onChange={(e) => setRealizedBy(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="realizedBy">
                                        <option value="">Selecione uma opção</option>
                                        <option value="Loteria Federal">Loteria Federal</option>
                                        <option value="Loteria dos sonhos">Loteria dos sonhos</option>
                                        <option value="Sorteador.com.br">Sorteador online</option>
                                        <option value="Live do Instagram">Live do Instagram</option>
                                        <option value="Live do YouTube">Live do YouTube</option>
                                        <option value="Live do Tiktok">Live do Tiktok</option>
                                    </select>
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label className="blocxk text-gray-700 text-sm font-bold mb-2" htmlFor="realizedAt">
                                        Data do sorteio
                                    </label>
                                    <input onChange={(e) => setRealizedAt(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="realizedAt" type="date" placeholder="3" />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label className="blocxk text-gray-700 text-sm font-bold mb-2" htmlFor="goldenTicketsQuantity">
                                        Quantidade de tickets premiados
                                    </label>
                                    <input onChange={(e) => setGoldenTicketsQuantity(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="goldenTicketsQuantity" type="number" placeholder="3" />
                                </div>
                            </div>

                            <div className="mb-6">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                    Nome do prêmio
                                </label>
                                <input onChange={(e) => setPrizeName(e.target.value)} className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
                                    name="name" type="name" placeholder="nome do prêmio" />
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                                    Descrição do prêmio
                                </label>
                                <input onChange={(e) => setPrizeDescription(e.target.value)} className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
                                    name="description" type="description" placeholder="detalhes do prêmio" />
                            </div>
                            <div className='w-[100%] flex flex-row justify-between items-center'>
                                <div className="mb-6 w-[45%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
                                        Preço do prêmio
                                    </label>
                                    <input onChange={(e) => setPrizePrice(e.target.value)} className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
                                        name="price" type="price" placeholder="20.50" />
                                </div>
                                <div className="mb-6 w-[45%]">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="imageUrl">
                                        Link da imagem
                                    </label>
                                    <input onChange={(e) => setPrizeImageUrl(e.target.value)} className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
                                        name="imageUrl" type="imageUrl" placeholder="https://link-da-imagem.jpg" />
                                </div>
                            </div>
                        </div>


                        <div className="flex items-center justify-between">
                            <button className="bg-[#005B80]   text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                Criar Campanha
                            </button>
                        </div>

                    </form>

                </div>
                <ToastContainer/>
            </div>

        </>
    );

}
export default CampanhaForm;