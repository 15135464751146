import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorAlert } from "../../../utils";

const Pagamentos = ({ ticketPrice, id }) => {
  const [qtdTickets, setqtdTickets] = useState(1); // initial value is 1
  const navigator = useNavigate();
  const token = sessionStorage.getItem('token')

  const buyTickets = () => {
    sessionStorage.removeItem('paymentInfos')
    let datas = { 'qtdTickets': qtdTickets, 'totalPrice': (qtdTickets * ticketPrice).toFixed(2), 'id': id }
    sessionStorage.setItem('paymentInfos', JSON.stringify(datas)) // Convert to JSON string
    if (qtdTickets > 0) {
      token != null ? navigator('/checkout') : navigator('/login', { state: { redirect: '/checkout' } })
    }
    else {
      errorAlert('Você precisa ter pelo menos 1 ticket')
    }

  }
  const addTicket = () => {
    if(qtdTickets == 1){
        let tempAdd = qtdTickets;
        tempAdd = tempAdd + 4; 
        setqtdTickets(tempAdd);    
    }
    else{
        let tempAdd = qtdTickets;
        tempAdd = tempAdd + 5; 
        setqtdTickets(tempAdd);

    }
  }

  const removeTicket = () => {
    if (qtdTickets >= 1) { 
      let tempRmv = qtdTickets;
      tempRmv = tempRmv - 5; 
      setqtdTickets(tempRmv);
    }
  }
  const addMassTickt = (amount) => {
    let tempAdd = qtdTickets;
    tempAdd = tempAdd + amount
    setqtdTickets(tempAdd);
  }
  const clearAmount = () => {
    setqtdTickets(1); 
  }
  return (
    <div className="sm:w-[50%] w-[90%] my-[3%] flex flex-col justify-center items-center py-[3%] bg-white shadow-md  border-gray-400 border rounded-md ">
      <p className="p-[4%] font-bold">Selecione seus tickets</p>
      <div className="flex flex-row items-center justify-around w-[100%] p-[5%] overflow-x-auto">
        <div onClick={() => addMassTickt(10)} className=" text-2xl mx-[1%] cursor-pointer shadow-md border text-[#005B80] border-[#005B80] font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline">
          10
        </div>
        <div onClick={() => addMassTickt(100)} className=" text-2xl mx-[1%] cursor-pointer border text-[#005B80] border-[#005B80] font-bold py-2 px-4 rounded-lg shadow-md  focus:outline-none focus:shadow-outline">
          100
        </div>
        <div onClick={() => addMassTickt(1000)} className=" text-2xl mx-[1%] cursor-pointer border text-[#005B80] border-[#005B80] font-bold py-2 px-4 rounded-lg shadow-md  focus:outline-none focus:shadow-outline">
          1.000
        </div>
        <div onClick={() => addMassTickt(10000)} className=" text-2xl mx-[1%] cursor-pointer border text-[#005B80] border-[#005B80] font-bold py-2 px-4 rounded-lg shadow-md  focus:outline-none focus:shadow-outline">
          10.000
        </div>
        <div onClick={clearAmount} className="text-2xl mx-[1%] cursor-pointer text-red-600 border border-red-600  font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline">
          Limpar
        </div>
      </div>
      <div className="w-[80%] h-[1.5px]  bg-gray-300"></div>
      <div className="flex flex-row justify-around items-center py-[4%] w-[100%]">
        <button onClick={removeTicket} className="flex flex-row text-2xl border border-gray-400 shadow-md  text-black font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline">
          -
        </button>
        <h3 className="font-bold text-3xl">{qtdTickets}</h3>
        <button onClick={addTicket} className="flex flex-row text-2xl border border-gray-400  shadow-md  text-black font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline">
          +
        </button>
      </div>
            <div className="w-[80%] h-[1.5px] bg-gray-300"></div>

            <div className="flex flex-row justify-between my-[5%] items-center w-[90%]">
                <h1 className="font-bold text-2xl border border-gray-400 rounded-md p-[2%]"> R$: {(qtdTickets * ticketPrice).toFixed(2)} </h1>
                <button onClick={buyTickets} className="flex flex-row text-2xl text-white bg-[#005B80] font-bold py-2 px-4 my-2 rounded-lg focus:outline-none focus:shadow-outline">
                    Comprar!
                </button>

            </div>
        </div>
    )
}

export default Pagamentos;