import React from "react";
import { Link } from "react-router-dom";

const CampanhaCardAdm = ({ name, balance, img }) => {
    return (
        <div className="bg-white flex flex-col md:w-[20vw] w-[100%] h-[20vh] sm:m-[20px] rounded-xl border-2 border-gray-300/75 shadow-lg shadow-gray-800">
            <h1 class="p-[5%]">{name}</h1>
            <div className={balance>0?"flex h-[100%] w-[100%] m-auto text-bold text-green-500":"flex h-[100%] m-auto font-bold text-[1.3rem] text-red-500"} >R$ {balance} </div>
        </div>
    )
}

export default CampanhaCardAdm