import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavItem = ({ route, icon, title }) => {
    const location = useLocation();

    return (
        <Link to={route} style={{ textDecorationLine: 'none' }}>
                <div className={route === location.pathname ? "flex flex-row px-10 py-4 items-center text-[#FFB901]" : "flex flex-row px-10 py-4 items-center "}>
                <i className="text-3xl mr-2">{icon}</i>
                <p className=" text-2xl">{title}</p>
            </div>
        </Link>
    )
}
export default NavItem;