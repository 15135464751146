import React from "react";

const BilheteCard = (props) => {
    return (
        <div className=" bg-white h-[100%] w-[100%] flex flex-col justify-center items-center rounded-lg p-[5%] overflow-y-auto">
            <p className="py-[2%] font-semibold">{props.name}</p>
            <div className="w-[90%] h-[1.5px] m-auto  bg-gray-300"></div>
            <p className="font-semibold">{props.numbers.length} Tickets comprados</p>
            <div className="w-[90%] h-[1.5px] m-auto bg-gray-300"></div>
            <p className="py-[2%] font-semibold">Seus números:</p>
            <div className="grid grid-container grid-cols-3 w-[100%] px-[2%] gap-[1%] overflow-y-auto">
                {props.numbers.map((data, index) => (
                    <div className="grid-item" >
                        <div className={data.isGolden ? 'bg-black text-white px-[10%] py-[5%] text-center rounded-full' : 'bg-blue-500 text-white w-[100%] px-[10%] py-[5%] text-center rounded-full'}>
                            <p className="sm:text-sm text-[80%]" key={index}> {data.number} </p>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}
export default BilheteCard;