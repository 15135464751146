import React, { useLayoutEffect, useState } from "react";
import NavItem from "./components/NavItem";
import Logo from '../assets/logo.png'
import { Link } from "react-router-dom";
import { MdLocalActivity, MdAttachMoney, MdInfo, MdSchool, MdSettings, MdExitToApp, MdStackedBarChart, MdSupportAgent } from "react-icons/md";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const NavBar = () => {
    let admUser = sessionStorage.getItem('adm');
    var token = sessionStorage.getItem('token');

    const [nav, setNav] = useState(false);
    const [privilege, setPrivilege] = useState(false);

    useLayoutEffect(() => {
        setPrivilege(admUser)

    }, [])

    const goTutorials = () => {
        window.location.href = "https://www.youtube.com/";
    }
    const goSupport = () => {
        window.location.href = "https://wa.me/5588998055660";
    }
    const handleNav = () => {
        setNav(!nav);
    };

    const cleanUser = () => {
        sessionStorage.clear();
    }

    return (
        <div className="h-screen">
            {privilege === "true" ?
                <div>
                    <div className="hidden md:flex w-[20vw] h-screen flex-col justify-stretch items-center shadow-md align-middle">
                        <img className='w-[40%] py-5' alt="logo" src={Logo} />
                        <div className="w-[80%] h-[1px] bg-gray-300"></div>
                        <div className="flex flex-col justify-center items-start">
                            <NavItem route={'/admin/campanhas'} icon={<MdLocalActivity />} title={'Campanhas'} />
                            <NavItem route={'/admin/Pagamentos'} icon={<MdAttachMoney />} title={'Pagamentos'} />
                            <NavItem route={'/admin/infos'} icon={<MdInfo />} title={'Informações'} />
                            {/* <NavItem route={'/home/perfil'} icon={<MdPerson />} title={'Perfil'} /> */}
                            <div onClick={goTutorials} className="flex cursor-pointer flex-row px-10 py-4 items-center ">
                                <i className="text-3xl mr-2"><MdSchool /></i>
                                <p className=" text-2xl">Tutoriais</p>
                            </div>
                            <div onClick={goSupport} className="flex cursor-pointer flex-row px-10 py-4 items-center ">
                                <i className="text-3xl mr-2"><MdSupportAgent /></i>
                                <p className=" text-2xl">Suporte</p>
                            </div>
                            <NavItem route={'/admin/configs'} icon={<MdSettings />} title={'Configurações'} />
                        </div>
                        <div className="flex flex-col pr-10 pt-[20%]">
                            {token != null ?
                                <Link to={'/'} style={{ textDecorationLine: 'none' }}>
                                    <div onClick={() => cleanUser()} className={"flex flex-row px-10 py-4 items-center "}>
                                        <i className="text-3xl mr-2"><MdExitToApp /></i>
                                        <p className=" text-2xl">Sair</p>
                                    </div>
                                </Link> : <Link to='/login'>
                                    <div className="flex flex-row px-10 py-4 items-center">
                                        <i className="text-3xl mr-2"><MdExitToApp /></i>

                                        <p className="text-2xl">Entrar</p>
                                    </div>
                                </Link>
                            }
                        </div>
                    </div>

                    <div onClick={handleNav} className='sm:hidden block'>
                        {nav ? <div className="z-50"><AiOutlineClose display={nav ? 'block' : 'hidden'} color="#000000" size={30} /> </div> : <div className="absolute p-[2%] right-0"><AiOutlineMenu color="#000000" size={30} /></div>}
                    </div>

                    <div className={nav ? 'bg-white fixed left-0 top-0 z-50 w-[60%] h-full ease-in-out duration-500' : ' hidden ease-in-out duration-500 left-[-100%]'}>
                        <div className="flex flex-col justify-stretch items-center shadow-md ">
                            <img className='w-[40%] py-5' alt="logo" src={Logo} />
                            <div className="w-[80%] h-[1px] bg-gray-300"></div>
                            <div className="flex flex-col justify-center items-start">
                                <NavItem route={'/home/campanhas'} icon={<MdLocalActivity />} title={'Campanhas'} />
                                <NavItem route={'/home/Pagamentos'} icon={<MdAttachMoney />} title={'Pagamentos'} />
                                <NavItem route={'/home/infos'} icon={<MdInfo />} title={'Informações'} />
                                {/* <NavItem route={'/home/perfil'} icon={<MdPerson />} title={'Perfil'} /> */}
                                <div onClick={goTutorials} className="flex cursor-pointer flex-row px-10 py-4 items-center ">
                                    <i className="text-3xl mr-2"><MdSchool /></i>
                                    <p className=" text-2xl">Tutoriais</p>
                                </div>
                                <div onClick={goSupport} className="flex cursor-pointer flex-row px-10 py-4 items-center ">
                                    <i className="text-3xl mr-2"><MdSupportAgent /></i>
                                    <p className=" text-2xl">Suporte</p>
                                </div>
                                {/* <NavItem route={'/home/configs'} icon={<MdSettings />} title={'Configurações'} /> */}
                            </div>
                            <div className="flex flex-col pr-10 pt-[20%]">
                                {token != null ?
                                    <Link to={'/'} style={{ textDecorationLine: 'none' }}>
                                        <div onClick={() => cleanUser()} className={"flex flex-row px-10 py-4 items-center "}>
                                            <i className="text-3xl mr-2"><MdExitToApp /></i>
                                            <p className=" text-2xl">Sair</p>
                                        </div>
                                    </Link> : <Link to='/login'>
                                        <div className="flex flex-row px-10 py-4 items-center">
                                            <i className="text-3xl mr-2"><MdExitToApp /></i>
                                            <p className="text-2xl">Entrar</p>
                                        </div>
                                    </Link>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                :
                <div>
                    <div className="hidden md:flex w-[20vw] h-screen flex-col justify-stretch items-center shadow-md align-middle">
                        <img className='w-[40%] py-5' alt="logo" src={Logo} />
                        <div className="w-[80%] h-[1px] bg-gray-300"></div>
                        <div className="flex flex-col justify-center items-start">
                            <NavItem route={'/user/campanhas'} icon={<MdLocalActivity />} title={'Campanhas'} />
                            <NavItem route={'/user/minhas-campanhas'} icon={<MdStackedBarChart />} title={'Meus bilhetes'} />
                            {/* <NavItem route={'/user/perfil'} icon={<MdPerson />} title={'Perfil'} /> */}
                            <div onClick={goTutorials} className="flex cursor-pointer flex-row px-10 py-4 items-center ">
                                <i className="text-3xl mr-2"><MdSchool /></i>
                                <p className=" text-2xl">Tutoriais</p>
                            </div>
                            <div onClick={goSupport} className="flex cursor-pointer flex-row px-10 py-4 items-center ">
                                <i className="text-3xl mr-2"><MdSupportAgent /></i>
                                <p className=" text-2xl">Suporte</p>
                            </div>
                            {/* <NavItem route={'/user/configs'} icon={<MdSettings />} title={'Configurações'} /> */}
                        </div>
                        <div className="flex flex-col pr-10 pt-[20%]">
                            {token != null ?
                                <Link to={'/'} style={{ textDecorationLine: 'none' }}>
                                    <div onClick={() => cleanUser()} className={"flex flex-row px-10 py-4 items-center "}>
                                        <i className="text-3xl mr-2"><MdExitToApp /></i>
                                        <p className=" text-2xl">Sair</p>
                                    </div>
                                </Link> : <Link to='/login'>
                                    <div className="flex flex-row px-10 py-4 items-center">
                                        <i className="text-3xl mr-2"><MdExitToApp /></i>
                                        <p className="text-2xl">Entrar</p>
                                    </div>
                                </Link>
                            }
                        </div>
                    </div>

                    <div onClick={handleNav} className='sm:hidden block'>
                        {nav ? <div className="z-auto"><AiOutlineClose display={nav ? 'block' : 'hidden'} color="#000000" size={30} /> </div> : <div className="absolute p-[2%] right-0"><AiOutlineMenu color="#000000" size={30} /></div>}
                    </div>

                    <div className={nav ? ' bg-white left-0 top-0 z-50 w-[90%] h-full ease-in-out duration-500' : ' hidden ease-in-out duration-500 left-[-100%]'}>
                        <div className="flex flex-col justify-stretch items-center shadow-md ">
                            <img className='w-[40%] py-5' alt="logo" src={Logo} />
                            <div className="w-[80%] h-[1px] bg-gray-300"></div>
                            <div className="flex flex-col justify-center items-start">
                                <NavItem route={'/user/campanhas'} icon={<MdLocalActivity />} title={'Campanhas'} />
                                <NavItem route={'/user/minhas-campanhas'} icon={<MdStackedBarChart />} title={'Meus bilhetes'} />
                                {/* <NavItem route={'/user/perfil'} icon={<MdPerson />} title={'Perfil'} /> */}
                                {/* <NavItem icon={<MdSchool />} title={'Tutoriais'} /> */}
                                <div onClick={goTutorials} className="flex cursor-pointer flex-row px-10 py-4 items-center ">
                                    <i className="text-3xl mr-2"><MdSchool /></i>
                                    <p className=" text-2xl">Tutoriais</p>
                                </div>
                                <div onClick={goSupport} className="flex cursor-pointer flex-row px-10 py-4 items-center ">
                                    <i className="text-3xl mr-2"><MdSupportAgent /></i>
                                    <p className=" text-2xl">Suporte</p>
                                </div>
                                {/* <NavItem route={'/user/configs'} icon={<MdSettings />} title={'Configurações'} /> */}
                            </div>
                            <div className="flex flex-col pr-10 pt-[20%]">
                                {token != null ?
                                    <Link to={'/'} style={{ textDecorationLine: 'none' }}>
                                        <div onClick={() => cleanUser()} className={"flex flex-row px-10 py-4 items-center "}>
                                            <i className="text-3xl mr-2"><MdExitToApp /></i>
                                            <p className="text-2xl">Sair</p>
                                        </div>
                                    </Link> : <Link to='/login'>
                                        <div className="flex flex-row px-10 py-4 items-center">
                                            <i className="text-3xl mr-2"><MdExitToApp /></i>
                                            <p className="text-2xl">Entrar</p>
                                        </div>
                                    </Link>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            }
        </div>
    );
}

export default NavBar;
