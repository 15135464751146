import React from "react";
import { Link } from "react-router-dom";


const FastStartCard = ({ icon, title, route }) => {
    return (
        <div className="pt-5">
            <Link to={route} state={{ toPayment: false  }} style={{ textDecorationLine: 'none' }}>
                <div class="flex flex-col justify-center items-center border border-gray-400 rounded-md p-4 w-64 h-48 shadow-md text-[#FFB901]">
                    <i className="text-6xl mr-2">{icon}</i>
                    <p>{title}</p>
                </div>
            </Link>
        </div>
    )
}

export default FastStartCard;