import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { postAdmLogin } from '../../Requests';
import { toastError } from '../../utils';

const AdminLogin = () => {

    const [loading, setloading] = useState(false);
    const [username, setUsername] = useState(false);
    const [password, setPassword] = useState(false);

    const navigator = useNavigate();

    const handleSubmit = async (event) => {
        setloading(true);
        event.preventDefault();
        setloading(false)
        try {
            const response = await postAdmLogin(username, password)
            navigator('/user/campanhas');
        } catch (error) {
            toastError("Verifique as informações!");
            setloading(false)
        }


    }
    return (
        <div className="w-full m-auto max-w-[90%]">
                <div className="modal">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit} className="justify-between h-[100%] px-4 pb-8 mb-4">
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                    Identificação
                                </label>
                                <input onChange={(e)=>setUsername(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="username" type="text" placeholder="Email ou telefone" />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                    Senha
                                </label>
                                <input onChange={(e)=>setPassword(e.target.value)}  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="password" type="password" placeholder="Senha" />
                            </div>

                            {!loading && (
                                <div className="flex w-[100%] items-center justify-center">
                                    <div >
                                        <button className="bg-[#005B80] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={() => handleSubmit} type="submit">
                                            Continuar
                                        </button>
                                    </div>
                                </div>
                            )}
                        </form>

                        {loading && (
                            <div className='flex flex-row items-center justify-center'>
                                <button className="flex flex-row bg-[#005B80]   text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                                    <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                                    </svg>
                                    Processando...
                                </button>
                            </div>
                        )}


                    </div>
                </div>
            <ToastContainer />
        </div >

    );
}
export default AdminLogin