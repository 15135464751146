import React from "react"

const GoldenTickets = ({ tickets }) => {
    return (
        <div className="sm:w-[50%] w-[90%] my-[3%] flex flex-col justify-center items-center py-[3%] bg-white shadow-md  border-gray-400 border rounded-md ">
            <p className="p-[4%] font-bold">Tickets Premiados</p>
            {tickets.map((data, index) => (
                <div className={data.isChosen ? 'w-[90%] flex flex-row justify-around bg-black  rounded-lg py-[2%] my-[2%]' : 'w-[90%] flex flex-row justify-around bg-[#005B80] rounded-lg py-[2%] my-[2%]'}>
                    <p className="text-white font-bold">{data.isChosen? "Resgatado": "Não Resgatado"}</p>
                    <div className="w-[2px] h-[100%] bg-black"></div>
                    <p className="text-white font-bold">{data.code}</p>
                </div>
            ))}
        </div>
    )
}
export default GoldenTickets