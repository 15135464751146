    import React, { useEffect, useState } from 'react';
    import { Link } from 'react-router-dom';
    import { getCampaigns } from '../../Requests';
    import BreadCumb from '../../components/BreadCumb';
    import CampanhaCard from './components/CampanhaCard';
    import CampanhaCardAdm from './components/CampanhaCardAdm';
    import Loader from '../../components/Loader';

    const Campanhas = () => {
        const [campaigns, setCampaigns] = useState([]);
        const [isPrivileged, setIsPrivileged] = useState(false);

        useEffect(() => {
            const admUser = sessionStorage.getItem('adm');
            setIsPrivileged(admUser === 'true');
            loadCampaigns();
        }, []);

        const loadCampaigns = async () => {
            try {
                const response = await getCampaigns();
                setCampaigns(response);
            } catch (error) {
                console.error(error);
            }
        };

        if (!campaigns.length) {
            return <div className='sm:w-[80vw] w-[100vw] h-[100vh] flex flex-col m-auto'><Loader /></div>;
        }

        return (
            <div className="flex sm:h-[80%] flex-col justify-around items-center w-[100%]">
                <BreadCumb item="Campanhas" subItens="Campanhas/" />
                <h1 className="p-[4%] font-bold">Lista de campanhas</h1>
                <div className="bg-white sm:w-[90%] w-[100vw] sm:h-[70%] h-[100%] m-auto border-slate-300 rounded-lg shadow-lg shadow-gray-300 overflow-y-auto scroll-zero-bar">
                    <div className="flex flex-col m-auto w-[100%] self-start">
                        <div className="grid p-[20px] sm:grid-cols-3 gap-7 justify-between items-center grid-cols-1">
                            {campaigns.map((campaign, index) => (
                                isPrivileged ? (
                                    <Link to={`/admin/campanhas/${campaign.id}`} state={{ id: campaign.id }}>
                                        <CampanhaCardAdm
                                            key={campaign.id}
                                            name={campaign.name}
                                            balance={campaign.metadata.balance}
                                            entred={false}
                                        />
                                    </Link>
                                ) : (
                                    <Link to={`${campaign.id}`} state={{ id: campaign.id, entred: false }}>
                                        <CampanhaCard
                                            key={campaign.id}
                                            name={campaign.name}
                                            img={campaign.prize.imageUrl}
                                            entred={false}
                                        />
                                    </Link>
                                )
                            ))}
                        </div>
                    </div>
                    
                    {campaigns.length === 0 && (
                        <div className="h-[10%] w-[40%] flex justify-center items-center bg-[#FFB901] text-white m-auto rounded-lg border-slate-300 shadow-lg shadow-gray-500">
                            Não há salas criadas!
                        </div>
                    )}
                </div>
                {isPrivileged && (
                    <Link to="/admin/criar-campanhas">
                        <button className="bg-[#005B80] absolute bottom-10 right-1/3 text-white font-bold py-2 px-12 rounded focus:outline-none focus:shadow-outline">
                            Criar Campanha
                        </button>
                    </Link>
                )}
            </div>
        );
    };

    export default Campanhas;