
import { toast } from 'react-toastify';

////////////////////// Utils //////////////////////


export const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

export const removeCharactersAndSpecialCharacter = (value) => {
    return value.replace(/[^0-9]/g, "");
};

////////////////////// Alerts //////////////////////

const Swal = require('sweetalert2')


export const errorAlert = (message) => {
    Swal.fire({
        title: 'Ops!',
        text: message,
        icon: 'error',
        confirmButtonText: 'Ok'
    })
}
export const sucessAlert = (message) => {
    Swal.fire({
        title: '',
        text: message,
        icon: 'success',
        confirmButtonText: 'Ok'
    })
}

////////////////////// Toasts //////////////////////

export const toastInfo = (message) => {

    toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export const toastError = (message) => 
    toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
});

export const toastSuccess = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
});