import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import BreadCumb from "../../components/BreadCumb";
import { getCampaignsDetails } from "../../Requests";
import { useState } from "react";
import Pagamentos from "../pagamentos/components/Pagamentos";
import { MdPix, MdStars } from "react-icons/md";
import GoldenTickets from "./components/GoldenTickets";

const CampanhaDetails = () => {
    const [datas, setDatas] = useState(null);
    const [formattedText, setFormattedText] = useState(null);
    const infos = useParams();
    useLayoutEffect(() => {
        loadData()
    }, []);
    const loadData = async () => {
        var response
        response = await getCampaignsDetails(infos.id)
        setDatas(response)
        const formattedText = proccessText(response.prize.description)
        setFormattedText(formattedText)
    }
    const proccessText = (txt) => {
        const regex = /\n/g;
        const text = txt;
        const formattedText = text.replace(regex, '<br>');
        return formattedText;
    }

    return (
        <div className="flex flex-col justify-start items-center sm:w-[100%] w-[100vw]">
            <BreadCumb item='Campanhas' subItens={`Campanhas / detalhes`} />
            {datas != null ?
                <div className="flex flex-col mt-[5%] justify-around items-center sm:w-[80vw] w-[100vw]">
                    <div className="flex flex-col w-[30%] justify-center items-center">
                        <div className="sm:w-[100%] w-[90vw] h-[50vh] border-slate-300 rounded-lg shadow-lg shadow-gray-500" style={{
                            backgroundImage: `url(${datas.prize.imageUrl})`, backgroundSize: '100% 100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',

                        }}>
                            <h1 className="p-[4%] text-white font-bold">{datas.name}</h1>
                        </div>
                    </div>
                    <div className="bg-white sm:w-[40%] w-[90vw] h-[100%] mb-2 border-slate-300 rounded-lg shadow-lg shadow-gray-500" >
                        <div className="flex flex-col items-center">
                            <h1 className="p-[4%] font-bold">Informações de campanhas</h1>
                            <div className="flex flex-row w-[100%] py-3 px-8 justify-between">
                                <p>Preço do ticket :</p>
                                <p>R$: {datas.entryPrice}</p>
                            </div>
                            <div className="w-[80%] h-[1px] bg-gray-300"></div>
                            <div className="my-[20%] text-sm text-center">
                                <p dangerouslySetInnerHTML={{ __html: formattedText }} />
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[50%] w-[90%] my-[3%] flex flex-col justify-center items-center py-[3%] bg-white shadow-md  border-gray-400 border rounded-md ">
                        <p className="p-[4%] font-bold">Ranking geral</p>
                        <p className="p-[4%] font-medium">Valendo um Iphone 15 pro max</p>
                        <div className="flex sm:flex-row flex-col sm:items-center sm:text-center w-[full]">
                            <div className="flex flex-col items-center">
                                <MdStars color="#ffda05" size={45}></MdStars>
                                <p className="font-bold">{datas.ranking[0].user.name}</p>
                                <p>{datas.ranking[0].quantity} bilhetes (R${datas.ranking[0].quantity * datas.entryPrice})</p>
                            </div>
                            <div className="flex flex-col items-center sm:my-0 sm:mx-12 my-12">
                                <MdStars color="#c9c9c9" size={45}></MdStars>
                                <p className="font-bold">{datas.ranking[1].user.name}</p>
                                <p>{datas.ranking[1].quantity} bilhetes (R${datas.ranking[1].quantity * datas.entryPrice})</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <MdStars color="#6a3805" size={45}></MdStars>
                                <p className="font-bold">{datas.ranking[2].user.name}</p>
                                <p>{datas.ranking[2].quantity} bilhetes (R${datas.ranking[2].quantity * datas.entryPrice})</p>
                            </div>

                        </div>
                    </div>
                    <GoldenTickets tickets={datas.goldenTickets} />
                    <Pagamentos ticketPrice={datas.entryPrice} id={datas.id} />
                    <div className="sm:w-[100%] flex justify-around mb-8 w-[90vw]">
                        <div className="w-[48%]  bg-white h-[25vh] border-slate-300 rounded-lg shadow-lg shadow-gray-30">
                            <p className="p-[4%] text-sm font-bold">Pagamento</p>
                            <h1 className="flex flex-row text-xl justify-center items-center h-[70%]"><MdPix size={20} /> Pix</h1>

                        </div>
                        <div className="w-[48%]  bg-white h-[25vh] border-slate-300 rounded-lg shadow-lg shadow-gray-300">
                            <p className="p-[4%] text-sm font-bold">Sorteio realizado por</p>
                            <h1 className="flex flex-col justify-center items-center h-[70%]">Loteria Federal</h1>
                        </div>
                    </div>
                </div> : <div className="flex flex-row h-[10vh] w-[100%] justify-center items-center m-auto"><span class="relative flex h-9 w-9">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-9 w-9 bg-sky-500"></span>
                </span></div>
            }
        </div>

    )
}
export default CampanhaDetails;

//login para pix//

// Suporte //