import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home/Home';
import FastStart from './pages/start/fastStart';
import Campanhas from './pages/campanhas/Campanha';
import Configuracoes from './pages/configuracoes/Configurações';
import LoginPage from './login/LoginPage';
import CampanhaForm from './pages/campanhas/components/CampanhaForm';
import CheckOut from './pages/pagamentos/Checkout';
import CampanhaDetails from './pages/campanhas/CampanhaDetails';
import Cadastro from './login/Cadastro';
import MeusBilhetes from './pages/campanhas/meus-bilhetes/MeusBilhetes';
import Insights from './pages/informacoes/Insights';

const router =

  createBrowserRouter([
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/cadastro",
      element: <Cadastro />,
    },
    {
      path: "/",
      element: <FastStart />,
    },

    {
      path: "checkout",
      element: <CheckOut />,
    },
    {
      path: "/admin",
      element: <Home />,
      children: [
        {
          path: "campanhas",
          element: <Campanhas />,
          children: [
          ]
        },
        {
          path: "criar-campanhas",
          element: <CampanhaForm />,
        },
        {
          path: "campanhas/:id",
          element: <Insights />
        },
        {
          path: "configs",
          element: <Configuracoes />

        },
      ]
    },
    {
      path: "/user",
      element: <Home />,
      children: [
        {
          path: "campanhas",
          element: <Campanhas />,
        },
        {
          path: "campanhas/:id",
          element: <CampanhaDetails />,
        },
        {
          path: "minhas-campanhas",
          element: <MeusBilhetes />,
        },
        {
          path: "configs",
          element: <Configuracoes />

        },
      ]
    }

  ])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
