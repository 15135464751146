import React from "react";

const InfoCard = ({color, title, content}) => {
    return (
        <div className="flex sm:flex-row flex-col w-[100%] text-center justify-around items-center p-[2%]">
            <div className="w-[100%] bg-white h-[15vh] border-slate-300 rounded-lg shadow-lg shadow-gray-30">
                <p className={`p-[4%] ${color} text-sm font-bold`}>{title}</p>
                <h1 className="font-bold text-2xl">{content}</h1>
            </div>
        </div>
    )
}
export default InfoCard;