import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postLogin } from '../../Requests';
import { ToastContainer } from 'react-toastify';
import { errorAlert, removeCharactersAndSpecialCharacter, toastError, toastInfo } from '../../utils';
import ReactInputMask from 'react-input-mask';
import Swal from 'sweetalert2';

const SimpleLogin = ({ extraInfos }) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const navigator = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {

            if (name === '' || email === '') {
                toastError("Nome e email obrigatórios!")
            }
            else {
                phone === '' ?
                    Swal.fire({
                        title: 'Confirme suas informações',
                        text: `${name} \n ${email} \n `,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Confirmar',
                        cancelButtonText: 'Voltar'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            postLogin(name, email.toLocaleLowerCase(), removeCharactersAndSpecialCharacter(''))
                                .then((response) => {
                                    extraInfos != null ? navigator('/checkout') : navigator('/user/campanhas');

                                })
                                .catch((error) => {
                                    if (error.response.status === 422) {
                                        toastError("Telefone já cadastrado");
                                    } else if (error.response.status === 409) {
                                        errorAlert("Telefone já incorreto");
                                    } else {
                                        errorAlert("Verifique as informações!");
                                    }
                                });
                        }
                    }) : Swal.fire({
                        title: 'Confirme suas informações',
                        text: `${name}\n ${email} Telefone: ${phone}`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Confirmar',
                        cancelButtonText: 'Voltar'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            postLogin(name, email.toLocaleLowerCase(), removeCharactersAndSpecialCharacter(phone))
                                .then((response) => {
                                        extraInfos != null ? navigator('/checkout', { state: extraInfos }) : navigator('/');
                                })
                                .catch((error) => {
                                    if (error.response.status === 422) {
                                        errorAlert("Telefone já cadastrado");
                                    } else if (error.response.status === 409) {
                                        errorAlert("Telefone incorreto");
                                    } else {
                                        errorAlert("Verifique as informações!");
                                    }
                                });
                        }
                    }
                    );
            };

        } catch (error) {
            console.log(error)
            toastError("Verifique as informações!")
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full m-auto max-w-[90%]">
            <div className="modal">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} className="justify-between h-[100%] px-4 pb-8 mb-4">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                Nome
                            </label>
                            <input value={name} onChange={(e) => setName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                name="name" type="text" placeholder="João da silva lima" />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                name="email" type="text" placeholder="funalo@gmail.com" />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                                Telefone (opcional)
                                <ReactInputMask
                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                    mask="(99) 9 9999-9999"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    errorMessages={['Campo obrigatório', '']}
                                />
                            </label>
                        </div>
                    </form>
                    <div className={`flex w-[100%] items-center justify-center ${loading ? 'loading' : ''}`}>
                        {loading ? (
                            <button className="flex flex-row bg-[#005B80]  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled>
                                Processando...
                            </button>
                        ) : (
                            <button className="bg-[#005B80] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleSubmit} type="submit">
                                Continuar
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default SimpleLogin;