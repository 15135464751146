import React, { useState } from 'react';
import SimpleLogin from './components/SimpleLogin';
import AdminLog from './components/AdminLog';
import Logo from '../assets/logo.png';
import { useLocation } from 'react-router-dom';

const LoginPage = () => {
    const [loginChoice, setLoginChoice] = useState('Simple');

    const navigator = useLocation();
    const infos = navigator.state

    const handleLoginType = (type) => {
        setLoginChoice(type);
    };

    const loginComponents = {
        Simple: SimpleLogin,
        Admin: AdminLog,
    };

    return (
        <div className="bg-abstract bg-cover flex sm:flex-row flex-col bg-center h-screen justify-around items-center w-[100%]">
            {console.log(infos)}
            <div className="text-center text-[#005B80] font-bold text-5xl">
                <h1>Bem vindo!</h1>
                <h1>à Premier Brasil</h1>
            </div>
            <div className="sm:h-[80%] h-[65%] bg-white shadow-md sm:w-[40vw] w-[90%] border-gray-400 border rounded-md border-">
                <div className="flex flex-col justify-around items-center h-[100%]">
                    <img
                        className="w-[20%] py-5"
                        alt="logo"
                        src={Logo}
                        onDoubleClick={() => handleLoginType('Admin')}
                    />
                    {loginChoice === 'Simple'? <SimpleLogin extraInfos={infos}/> : <AdminLog />

                    }
                    {/* {loginComponents[loginChoice] && React.createElement(loginComponents[loginChoice], {})} */}
                                    </div>
            </div>
        </div>
    );
};

export default LoginPage;