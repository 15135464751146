import React from 'react';

const ProgressBar = ({ progress, color }) => {
    return (
        <>
            <div className="w-[90%] flex flex-row items-center justify-between bg-white h-4 rounded-full">
                <div
                    className={`bg-[${color}] h-4 rounded-full`}
                    style={{ width: `${progress}%` }}
                ></div>
            </div>
        </>
    );
};

export default ProgressBar;