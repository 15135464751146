import React from "react";

const Configuracoes = () =>{
    return(
        <div>
            <p>Configuracoes</p>
        </div>
    )
}

export default  Configuracoes;