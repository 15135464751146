import axios from 'axios';
import { isValidEmail } from './utils';
const api = axios.create({
    baseURL: 'https://api.premierbrasilumbix.com.br/',
})
var TOKEN = `Bearer ${sessionStorage.getItem('token')}`

export const criarNovoUsuario = async (name, email, password, phone) => {
    var req = await api.post('users', {
        name: name,
        email: email,
        password: password,
        phone: phone
    }).then((e) => {
        return e.status
    })
    return req;
}

export const getUsers = async (id) => {
    var req = await api.get(`campaigns/${id}/tickets`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': TOKEN
        }
    }).then((e) => {
        return e.data
    })
    return req;
}

export const validatePayment = async (id) => {
    var req = await api.get(`payments/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': TOKEN
        }
    }).then((e) => {
        return e.data
    })
    return req;
}

export const postLogin = async (name, email, phone) => {
    const loginData = phone === ''
        ? {
            name,
            email: email,
        }
        : {
            name: name,
            email: email,
            phone: phone,
        };
    var req = await api.post('auth/login', loginData).then((e) => {
        sessionStorage.setItem('token', e.data.accessToken);
        sessionStorage.setItem('adm', e.data.isAdmin);
        return e.status
    })
    return req
}

export const postAdmLogin = async (username, password) => {
    var req = await api.post('auth/login', {
        name: 'Guedes',
        email: username,
        password: password,
    }).then((e) => {
        sessionStorage.setItem('token', e.data.accessToken);
        sessionStorage.setItem('adm', e.data.isAdmin);
        return e
    })
    return req
}

export const getCampaigns = async () => {
    var req = await api.get('campaigns', {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((e) => {
        return e.data
    })
    return req;
}
export const getCampaignsDetails = async (id) => {
    var req = await api.get(`campaigns/${id}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((e) => {
        return e.data
    })
    return req;
}
export const getMyTickets = async (searchInfo) => {
    let param;
    isValidEmail(searchInfo) ? param = `email=${searchInfo}` : param = `phone=${searchInfo}`

    var req = await api.get(`/users/tickets?${param}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((e) => {
        return e.data
    })
    return req;
}

export const getValidarToken = async () => {
    var req = await api.get(`/auth/tokens/check`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': TOKEN

        }
    }).then((e) => {
        return e.status;
    })
    return req;
}


export const criarNovaCampanha = async (name, entryPrice, ticketAmount, goldenTicketsQuantity, realizedBy, realizedAt, prizeName, prizeDescription, prizePrice, prizeImg) => {

    var req = await api.post('campaigns', {

        name: name,
        entryPrice: parseFloat(entryPrice),
        ticketAmount: parseInt(ticketAmount),
        goldenTicketsQuantity: goldenTicketsQuantity,
        realizedBy: realizedBy,
        supportNumber: 88996929403,
        realizedAt: "2025-12-04T15:30",
        prize: {
            name: prizeName,
            description: prizeDescription,
            price: prizePrice,
            imageUrl: prizeImg
        }
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': TOKEN
        }
    }).then((e) => {
        return e.status
    })
    return req;
}
export const atualizarCampanha = async (id, name, entryPrice, realizedAt, status) => {
    realizedAt = new Date();
    const formattedDate = `${realizedAt.getFullYear()}-${String(realizedAt.getMonth() + 1).padStart(2, '0')}-${String(realizedAt.getDate()).padStart(2, '0')}T${String(realizedAt.getHours()).padStart(2, '0')}:${String(realizedAt.getMinutes()).padStart(2, '0')}`;

    var req = await api.patch(`campaigns/${id}`, {
        name: name,
        entryPrice: parseFloat(entryPrice),
        // realizedBy: realizedBy,
        realizedAt: realizedAt != null ? formattedDate : realizedAt,
        status: status == true ? 'open' : 'closed'
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': TOKEN
        }
    }).then((e) => {
        return e.status
    })
    return req;
}