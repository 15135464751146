import React, { useState } from 'react';
import { criarNovoUsuario } from "../Requests";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { MdRemoveRedEye } from 'react-icons/md';
import ReactInputMask from 'react-input-mask';


const CadastroForm = ({ handleClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPasswordw2] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setloading] = useState(false);
  const [type, setType] = useState('password');
  const [type2, setType2] = useState('password');
  const [icon, setIcon] = useState(true);
  const navigator = useNavigate();

  const handleSubmit = async (event) => {
    setloading(true)
    event.preventDefault();
    if (password === password2) {
      let noMaskPhone = removeCharactersAndSpecialCharacter(phone)
      
      var requestStatus = await criarNovoUsuario(name, email, password, noMaskPhone)
      if (requestStatus === 201) {
        toastSuccess();
        window.history.back();
      }
      if (requestStatus === 400) {
        return
      }
    } else {
      toastError();
    }
  }

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(true);
      setType('text');
    } else {
      setIcon(false);
      setType('password');
    }
  };
  const removeCharactersAndSpecialCharacter = (value) => {
    return value.replace(/[^0-9]/g, "");
  };
  const toastError = () => toast.error("Senhas diferentes!", {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
  const toastSuccess = () => toast.success("Cadastro criado!", {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

  return (
    <div className=''>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Nome:
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={name} onChange={(event) => setName(event.target.value)} {...loading ? 'disabled' : ''} />
          </label>
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email:
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={email} onChange={(event) => setEmail(event.target.value)} {...loading ? 'disabled' : ''} />
          </label>
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
            Telefone:
            <ReactInputMask
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              mask="(99) 9 9999-9999"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              // validators={['required', validateCreditCard]}
              errorMessages={['Campo obrigatório', '']}
            />
          </label>
        </div>
        <div className='flex justify-between items-center py-6'>
          <div onClick={() => {
            window.history.back();
          }} className=" text-blue-500 cursor-pointer font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline">
            Voltar
          </div>
          <button className=" bg-[#005B80]   text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Cadastrar
            <ToastContainer />

          </button>
        </div>
      </form>
    </div>
  )
}

export default CadastroForm;